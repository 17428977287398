import React, { useState } from "react";
import DateTimePicker from 'react-datetime-picker';
import dateFormat from 'dateformat';

import ReadingsDataService from "../../services/ReadingsService";

const AddReadings = () => {
    const initialReadingsState = {
        date: "",
        first_reading: "",
        psalm: "",
        psalm_response: "",
        second_reading: "",
        gospel: ""
    };
    const [readings, setReadings] = useState(initialReadingsState);
    const [submitted, setSubmitted] = useState(false);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setReadings({ ...readings, [name]: value });
    };

    const handleDateChange = (value) => {
        setReadings({ ...readings, "date": value });
    };

    const saveReadings = () => {
        const dateString = dateFormat(readings.date, "yyyy-mm-dd");

        var data = {
            date: dateString,
            first_reading: readings.first_reading,
            psalm: readings.psalm,
            psalm_response: readings.psalm_response,
            second_reading: readings.second_reading,
            gospel: readings.gospel
        };

        console.log(data);

        ReadingsDataService.create(data)
            .then(response => {
                console.log(response.data);
                setReadings({
                    date: new Date(response.data.date),
                    first_reading: response.data.first_reading,
                    psalm: response.data.psalm,
                    psalm_response: response.data.psalm_response,
                    second_reading: response.data.second_reading,
                    gospel: response.data.gospel
                });
                setSubmitted(true);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const newReadings = () => {
        setReadings(initialReadingsState);
        setSubmitted(false);
    };

    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">Agregar lecturas para la misa</h4>
                <div className="submit-form">
                    {submitted ? (
                        <div>
                            <p>Lecturas del día {readings.date.toLocaleString()}</p>
                            <button className="btn btn-primary" onClick={newReadings}>
                                Agregar lecturas de otro día
                            </button>
                        </div>
                    ) : (
                            <div>
                                <div className="form-group">
                                    <label htmlFor="date">Fecha: </label>
                                    <DateTimePicker required="true" name="date" value={readings.date} onChange={handleDateChange} format="dd-MM-y" locale="es-GT" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="first_reading">Primera Lectura: </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="first_reading"
                                        required
                                        value={readings.first_reading}
                                        onChange={handleInputChange}
                                        name="first_reading"
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="psalm">Salmo: </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="psalm"
                                        required
                                        value={readings.psalm}
                                        onChange={handleInputChange}
                                        name="psalm"
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="psalm_response">Respuesta al Salmo: </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="psalm_response"
                                        required
                                        value={readings.psalm_response}
                                        onChange={handleInputChange}
                                        name="psalm_response"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="second_reading">Segunda Lectura: </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="second_reading"
                                        value={readings.second_reading}
                                        onChange={handleInputChange}
                                        name="second_reading"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="gospel">Evangelio: </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="gospel"
                                        required
                                        value={readings.gospel}
                                        onChange={handleInputChange}
                                        name="gospel"
                                    />
                                </div>
                                <button onClick={saveReadings} className="btn btn-primary">Enviar</button>
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
};

export default AddReadings;