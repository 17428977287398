import React from "react";
import "./theme.scss";

import Routers from "./router";

function App() {
  return (
    <Routers />
  );
}

export default App;
