import React, { useState, useEffect, Fragment } from "react";
import ReadingsDataService from "../../services/ReadingsService";

const ShowReadings = (props) => {
    const [todayReadings, setTodayReadings] = useState({});
    const [currentReading, setCurrentReading] = useState({});

    useEffect(() => {
        retrieveReadings();
    }, []);

    const changeReading = (data) => {
        let title = "";
        let description = "";
        console.log(todayReadings);
        if (props.reading === "first_reading") {
            title = "Primera Lectura"
            description = data.first_reading;
        } else if (props.reading === "psalm") {
            title = "Salmo Responsorial"
            description = data.psalm + ': "' + data.psalm_response + '"';
        } else if (props.reading === "second_reading") {
            title = "Segunda Lectura"
            description = data.first_reading;
        } else if (props.reading === "gospel") {
            title = "Evangelio"
            description = data.first_reading;
        } else {
            title = "Test";
            description = "GH";
        }
        setCurrentReading({ title: title, description: description });
    };

    const retrieveReadings = () => {
        ReadingsDataService.getReadingsList("today").then(response => {
            setTodayReadings(response.data[0]);
            changeReading(response.data[0]);
        }).catch(e => {
            setTodayReadings([]);
            console.log(e);
        });
    };

    return (
        <div id="reading">
            <div className="title">
                {currentReading.title}
            </div>
            <div className="description">
                {currentReading.description}
            </div>
        </div>
    );
};

export default ShowReadings;