import React, { useState, useEffect, Fragment } from "react";
import ReactDOM from 'react-dom';
import Countdown, { zeroPad } from 'react-countdown';

const Intro = () => {
    const setDateZero = (date) => {
        return date < 10 ? '0' + date : date;
    }

    const today = new Date();
    const start_time = new Date(today.getFullYear() + '-' + setDateZero(today.getMonth() + 1) + '-' + setDateZero(today.getDate()) + 'T23:00:00Z');


    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return "Empezará pronto";
        } else {
            // Render a countdown
            return <span>{zeroPad(hours)} : {zeroPad(minutes)} : {zeroPad(seconds)}</span>;
        }
    };

    return (
        <div>
            <Countdown date={start_time} renderer={renderer} />
        </div>
    );
};

export default Intro;