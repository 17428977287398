import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import dateFormat from 'dateformat';

import IntentionDataService from "../../services/IntentionService";

const IntentionsList = (props) => {
    const [intentionsList, setIntentionsList] = useState([]);

    const categories = [
        { "id": 1, "name": "Acción de gracias" },
        { "id": 2, "name": "Acción de gracias (Cumpleaños)" },
        { "id": 3, "name": "Acción de gracias (Aniversario)" },
        { "id": 4, "name": "Salud" },
        { "id": 5, "name": "Eterno descanso" },
        { "id": 6, "name": "Intención particular" },
    ]

    const labels = {
        "today": "de hoy",
        "tomorrow": "de mañana",
        "permanent": "permanentes",
        null: ""
    }

    useEffect(() => {
        retrieveIntentions();
    }, []);

    const retrieveIntentions = () => {
        IntentionDataService.getIntentionsList(props.type).then(response => {
            setIntentionsList(response.data);
            console.log(response.data);
        }).catch(e => {
            setIntentionsList([]);
            console.log(e);
        });
    };

    const deleteIntention = (id, e) => {
        e.preventDefault();
        IntentionDataService.remove(id).then(response => {
            setIntentionsList(intentionsList.filter(function (obj) {
                return obj.id !== id;
            }));
        }).catch(e => {
            setIntentionsList([]);
            console.log(e);
        });
    }

    return (
        <div className="card">
            <div className="card-body">
                <div className="card-title">
                    <div className="row">
                        <div className="col-6">
                            <h4 >Intenciones para la misa {labels[props.type]}</h4>
                        </div>
                        <div className="col-6">
                            <div className="float-right"><Link to={"/intentions/add"} className="btn btn-primary">Agregar intención</Link></div>
                        </div>
                    </div>
                </div>
                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th scope="col">No.</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Observación</th>
                            <th scope="col">Fecha</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {intentionsList && categories.map(
                            category => {
                                return (
                                    intentionsList.filter(intention => category.id === intention.category_id).map((intention, index) => (
                                        <Fragment key="{intention.id}">
                                            {index === 0 ? <tr className="table-active"><td colSpan="5"><b>{category.name}</b></td></tr> : ""}
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{intention.name}</td>
                                                <td>{intention.observation}</td>
                                                <td>{intention.permanent ? "Permanente" : dateFormat(intention.date, "dd-mm-yyyy", "UTC")}</td>
                                                <td>
                                                    <Link to={"/intentions/edit/" + intention.id} className="badge badge-warning">Editar</Link>
                                                    <button onClick={(e) => deleteIntention(intention.id, e)} className="badge badge-danger ml-1 border-0">Eliminar</button>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    ))
                                )
                            }
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default IntentionsList;