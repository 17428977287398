import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import dateFormat from 'dateformat';

import ReadingsDataService from "../../services/ReadingsService";

const ReadingsList = (props) => {
    const [readingsList, setReadingsList] = useState([]);

    useEffect(() => {
        retrieveReadings();
    }, []);

    const retrieveReadings = () => {
        ReadingsDataService.getReadingsList(props.type).then(response => {
            setReadingsList(response.data);
            console.log(response.data);
        }).catch(e => {
            setReadingsList([]);
            console.log(e);
        });
    };

    const deleteReadings = (id, e) => {
        e.preventDefault();
        ReadingsDataService.remove(id).then(response => {
            setReadingsList(readingsList.filter(function (obj) {
                return obj.id !== id;
            }));
        }).catch(e => {
            setReadingsList([]);
            console.log(e);
        });
    }

    return (
        <div className="card">
            <div className="card-body">
                <div className="card-title">
                    <div className="row">
                        <div className="col-6">
                            <h4 >Lecturas</h4>
                        </div>
                        <div className="col-6">
                            <div className="float-right"><Link to={"/readings/add"} className="btn btn-primary">Agregar lecturas</Link></div>
                        </div>
                    </div>
                </div>
                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th scope="col">Fecha</th>
                            <th scope="col">Lecturas</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {readingsList.map((readings, index) => (
                            <tr key="{readings.id}">
                                <td>{dateFormat(readings.date, "dd-mm-yyyy", "UTC")}</td>
                                <td>
                                    <div><span class="text-primary">Primera Lectura: </span> {readings.first_reading}</div>
                                    <div><span class="text-primary">Salmo: </span> {readings.psalm} <span class="font-italic">"{readings.psalm_response}"</span></div>
                                    {(readings.second_reading) ? <div><span class="text-primary">Segunda Lectura: </span>{readings.second_reading}</div> : ""}
                                    <div><span class="text-primary">Evangelio: </span>{readings.gospel}</div>
                                </td>
                                <td>
                                    <Link to={"/readings/edit/" + readings.id} className="badge badge-warning">Editar</Link>
                                    <button onClick={(e) => deleteReadings(readings.id, e)} className="badge badge-danger ml-1 border-0">Eliminar</button>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ReadingsList;