
import React from "react";
import { Switch, Route, Link, useRouteMatch, Redirect } from "react-router-dom";

import MainLayout from "../containers/layouts/MainLayout"

import Home from "../containers/views/Home";
import Intentions from "../containers/views/Intentions";
import AddIntention from "../components/intentions/AddIntention";
import EditIntention from "../components/intentions/EditIntention";
import Readings from "../containers/views/Readings";
import AddReadings from "../components/readings/AddReadings";
import ShowReading from "../components/readings/ShowReading";
import IntentionsRoll from "../components/intentions/IntentionsRoll";
import Intro from "../components/intro/Intro";
import FacebookRedirect from "../components/FacebookRedirect/FacebookRedirect";

const router = () => {
    return (
        <Switch>
            <Route exact path="/" component={FacebookRedirect} />

            <Route path="/admin">
                <Admin />
            </Route>

            <Route path="/obs">
                <OBS />
            </Route>
        </Switch>
    )
}

export default router;

function Admin() {
    let { path, url } = useRouteMatch();
    return (
        <MainLayout>
            <Switch>
                <Route exact path={`${path}/home`} component={Home} />
                <Route exact path={`${path}/intentions`} component={Intentions} />
                <Route exact path={`${path}/intentions/add`} component={AddIntention} />
                <Route exact path={`${path}/intentions/edit/:id`} component={EditIntention} />
                <Route exact path={`${path}/readings`} component={Readings} />
                <Route exact path={`${path}/readings/add`} component={AddReadings} />
            </Switch>
        </MainLayout>
    );
}

function OBS() {
    let { path, url } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}/intro`} component={Intro} />
            <Route exact path={`${path}/intenciones`} component={IntentionsRoll} />
            <Route path={`${path}/primeralectura`} render={(props) => (
                <ShowReading {...props} reading="first_reading" />
            )} />
            <Route path={`${path}/segundalectura`} render={(props) => (
                <ShowReading {...props} reading="second_reading" />
            )} />
            <Route path={`${path}/salmo`} render={(props) => (
                <ShowReading {...props} reading="psalm" />
            )} />
            <Route path={`${path}/evangelio`} render={(props) => (
                <ShowReading {...props} reading="gospel" />
            )} />
        </Switch>
    );
}